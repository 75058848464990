import { _cookie } from "@/plugins/Cookie";
import regex  from '@/utils/regex.js';
import qs from 'qs'

/**
 * 生成唯一UUID值
 */
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
/**
 * get token
 * @param {*} key
 * @param {*} data
 */
export function getACSRFToken(key, data) {
  var skey = key + data;
  var hash = 5381;
  if (skey) {
    for (var i = 0, len = skey.length; i < len; ++i) {
      hash += (hash << 5) + skey.charCodeAt(i);
    }
  }
  return hash & 0x7fffffff;
}
/**
 * 获取Api所需ua信息
 */
const generateUA = function(token = '', tokenType = 'default') {
  const version = 'ym_wxapp',
    platForm = window.location.href.indexOf("localhost:8080") > -1 ||
    window.location.href.indexOf("test.szxdzb.com") > -1 ? 1001 : 1000,
    requestId = uuidv4(),
    channelId = regex.params('channel') || '',
    subChannelId = _cookie.get('subChannel') || '99999999'

  if (!token) token = '^CE%JUm#r3w&)'
  let md5 = getACSRFToken(token, version + platForm + requestId)
  let result = `h5/${version}/${platForm}/0/0/0/${channelId}/${subChannelId}/0/0/0/${requestId}/0/0/${md5}`
  return result
}

/**
 * 校验object
 * @param value
 * @returns {boolean}
 */
export const isObject = value => {
  return typeof value === `object` && value !== null
}
/**
 *
 * @param dateStr
 * @param format
 * @returns {string}
 */
export const dateFormat = function(dateStr, format) {
  if (format === undefined) {
    format = 'yyyy-M-d h:m:s'
  }
  let date = new Date(dateStr)
  var map = {
    M: date.getMonth() + 1, //月份
    d: date.getDate(), //日
    h: date.getHours(), //小时
    m: date.getMinutes(), //小时
    s: date.getSeconds(), //小时
  }
  format = format.replace(/([yMdhmsqS])+/g, function(all, t) {
    var v = map[t]
    if (v !== undefined) {
      if (all.length > 1) {
        v = '0' + v
        v = v.substr(v.length - 2)
      }
      return v
    } else if (t === 'y') {
      return (date.getFullYear() + '').substr(4 - all.length)
    }
    return all
  })
  return format
}
/**
 * logTransferDc.do上报，以\u0001分割字符串上报reporttime,occurtime,mid,clientip,channelid,subchannelid,sessionid,mobileinfo,platform,network,version,data1,data2,data3,data4,data5,data6,data7,data8,data9,data10,data11,data12,data13,data14,data15,data16,data17,data18,data19,data20,data21,data22,data23,data24,data25,data26,data27
 * @param bodyConfig
 * @param onSucc
 * @param onError
 */
const logTransferDc = (bodyConfig, onSucc = null, onError = null) => {
  // let data15 = ''
  const placeholder = '\u0001'
  const nowTime = new Date().valueOf()
  const nowTimeFormat = dateFormat(new Date().valueOf(), 'yyyy-MM-dd hh:mm:ss')
  if (!isObject(bodyConfig)) return
  const {
    // 非必要参数
    mid = '',
    clientip = '',
    sessionid = '',
    mobileinfo = '',
    platform = '',
    network = '',
    version = '',
    reporttime = nowTime,
    occurtime = nowTimeFormat,
    channelid = '',
    subchannelid = '',
  } = bodyConfig
  // kibana上报参数
  const {
    resourceKey,
    accessPoint: data1,
    accessPointDesc: data2,
    extString1: data3,
    extString2: data4,
    extString3: data11,
    extString4: data12,
    extString5: data13,
    extString6: data15,
    extString7: data16,
    extString8: data17,
    extString9: data18,
    extString10: data19,
    extString11: data20,
    extString12: data21,
    extString13: data22,
    extString14: data23,
    extString15: data24,
    extString16: data25,
    extString17: data26,
    extString18: data27,
  } = bodyConfig
  // 非kibana上报参数 data14的下表是24 对应logTime
  const { biz = 0, iid, data5, data6, data7, data8, data9, data10, data14 } = bodyConfig
  const body = [
    reporttime,
    occurtime,
    mid,
    clientip,
    channelid,
    subchannelid,
    sessionid,
    mobileinfo,
    platform,
    network,
    version,
    data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    reporttime,
    data15,
    data16,
    data17,
    data18,
    data19,
    data20,
    data21,
    data22,
    data23,
    data24,
    data25,
    data26,
    data27,
  ]
  let data = {
    body: body.join(placeholder),
    headers: {
      iid: resourceKey || iid, // 表示接口，可选值：resourceKey | t_dc_xxxxx
      biz, // 表示上报事件id
    },
  }
  let token = '^CE%JUm#r3w&)'
  let _header = {
    'content-type': 'application/x-www-form-urlencoded;',
    Cookie: 'token=' + token
  }
  const baseURL =
    window.location.href.indexOf("localhost:8080") > -1 ||
    window.location.href.indexOf("test.szxdzb.com") > -1
      ? "https://api-test.zhenai.com/"
      : "https://api.zhenai.com/";
  fetch(`${baseURL}log/logTransferDc.do`, {
    method: "post",
    headers:_header,
    body: qs.stringify({
      data: JSON.stringify([data]),
      ua: generateUA()
    }),
  }).then(res=>{
    console.log(res);
  }).catch((err)=>{
    console.log(err,'err==');
  })
}


export default logTransferDc
