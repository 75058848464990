import reportPush from './reportPush'

export default {
    dataReportPush: async(data)=> {
        const changeParams = {}
        for (let i in data) {
            if (i.includes('extStr') && !i.includes('extString')) {
                changeParams[`extString${+i.split('extStr')[1]}`] = data[i]
            } else {
                changeParams[i] = data[i]
            }
        }

        const params = {
            ...changeParams
        }
        console.log(params)
        await reportPush(params)
    }
}
