/*
 * @Description: 
 * @version: 
 * @Author: tianguang.li
 * @Date: 2024-08-21 15:08:54
 * @LastEditors: tianguang.li
 * @LastEditTime: 2024-09-25 18:36:16
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/maker",
    name: "maker",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Maker.vue"),
  },
  {
    path: "/works",
    name: "works",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Works.vue"),
  },
  {
    path: '/matchmaking',
    name: 'matchmaking',
    component: () => import(/* webpackChunkName: "about" */ '../views/Matchmaking.vue')
  },
  {
    path: '/offline',
    name: 'offline',
    component: () => import(/* webpackChunkName: "about" */ '../views/Offline.vue')
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: `/zxc`,
  routes,
});

export default router;
